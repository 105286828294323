/* Add this to your nav.css file */
/* nav.css */

@media (max-width: 768px) {
  .py-1 {
    margin-left: -5px;
  }

  .x {
    margin-left: 10px;
  }

  .increase-margin-top {
    margin-top: 10px;
    /* Adjust the value as needed */
  }

  .branch-menu-container {
    display: block !important;
    /* Ensure the dropdown is shown */
    position: relative;
    width: 100%;
    /* Ensure it spans the full width */
    background-color: transparent !important;
    border-radius: 0;
    box-shadow: none !important;
    /* Remove the shadow */

  }

  .branch-menu-container a {
    color: black !important;
    /* Set the text color to black */
  }

}